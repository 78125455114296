* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Noto Sans JP", sans-serif;
}

@media (max-width: 1200px) {
  html {
    font-size: 51%;
  }
}

@media (max-width: 900px) {
  html {
    font-size: 38%;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 62.5%;
  }
}

body {
  box-sizing: border-box;
}

textarea:focus,
input:focus {
  outline: none;
  border-color: #00a261;
  box-shadow: none;
}

.container {
  width: 120rem;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .container {
    width: 98%;
  }
}

.favWrapper {
  background-color: #f9f9f9;
  padding: 2rem 0;
}

.headerContainer {
  background-color: #ffffff;
  border-bottom: #e0e0e0 thin solid;
  padding: 1rem;
}

@media (max-width: 480px) {
  .headerContainer {
    padding: 0.5rem;
  }
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header_left {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

@media (max-width: 480px) {
  .header_left {
    flex-direction: column;
    align-items: baseline;
  }
}

.header_right {
  background-color: none;
}

@media (max-width: 480px) {
  .header_right {
    display: none;
  }
}

.header_logo {
  display: flex;
  align-items: center;
  justify-content: baseline;
}

@media (max-width: 480px) {
  .header_logo {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.header_logo__img {
  width: 15rem;
}

@media (max-width: 480px) {
  .header_logo__img {
    width: 13rem;
  }
}

.header_logo_mobile_header {
  display: none;
}

.header_logo_mobile_header .userIcon {
  font-size: 1.8rem;
  cursor: pointer;
  padding-left: 2rem;
}

@media (max-width: 480px) {
  .header_logo_mobile_header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.header_search {
  width: 40rem;
  background-color: #f9f9f9;
  padding: 0.5rem;
  border: #00a261 thin solid;
  border-radius: 0.2em;
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
}

@media (max-width: 480px) {
  .header_search {
    width: 100%;
    margin-left: 0;
  }
}

.header_search__input {
  border: none;
  font-size: 1.3rem;
  background: transparent;
  padding: 0.25rem;
  flex-grow: 1;
  padding-right: 1rem;
  border-right: #cccccc thin solid;
}

.header_search__location {
  margin-right: 2rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #2b2a28;
  text-transform: uppercase;
  cursor: pointer;
}

.header_search__location span {
  font-size: 1rem;
}

.header_search__location__mapIcon {
  font-size: 1.4rem !important;
  margin-right: 0.5rem;
}

.header_search__location__caretIcon {
  font-size: 1.4rem !important;
  margin-left: 0.5rem;
}

.header_search__button {
  font-size: 1.4rem;
  color: #ffffff;
  background: #00a261;
  border: none;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.header_search__button:hover {
  background-color: #047d4d;
}

.header_menu {
  width: auto;
  display: flex;
  flex-direction: row;
}

.header_menu__sellButton {
  background-color: #00a261;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 600;
  border: #00a261 thin solid;
  padding: 0.6rem 1.4rem;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-left: 3rem;
}

.header_menu__sellButton i {
  margin-right: 0.3rem;
}

.header_menu__sellButton:hover {
  background-color: #047d4d;
  border-color: #047d4d;
}

.header_menu__authButton {
  background-color: transparent;
  color: #2b2a28;
  font-size: 1.4rem;
  font-weight: 400;
  border: none;
  cursor: pointer;
  margin-left: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header_menu__authButton i {
  font-size: 1.4rem;
  margin-right: 0.6rem;
}

.header_menu__authButton strong {
  font-weight: 500;
  font-size: 1.3rem;
  text-align: left;
}

.favContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto auto;
}

@media (max-width: 480px) {
  .favContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

.favItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-right: #e0e0e0 thin solid;
  border-bottom: #e0e0e0 thin solid;
  padding: 1rem 0;
}

@media (max-width: 480px) {
  .favItem {
    padding: 0.5rem;
  }
}

.favItem img {
  width: 4rem;
}

@media (max-width: 480px) {
  .favItem img {
    width: 3rem;
  }
}

.favItem h2 {
  font-size: 1.3rem;
  margin: 0.6rem 0;
}

@media (max-width: 480px) {
  .favItem h2 {
    font-size: 1rem;
    margin: 0.3rem 0;
  }
}

.favItem:last-child {
  border-right: none;
}

.favItem:nth-child(7) {
  border-right: none;
}

@media (max-width: 480px) {
  .favItem:nth-child(7) {
    border-right: #e0e0e0 thin solid;
  }
}

.favItem:nth-child(n + 8) {
  border-bottom: none;
}

@media (max-width: 480px) {
  .favItem:nth-child(n + 8) {
    border-bottom: #e0e0e0 thin solid;
  }
}

@media (max-width: 480px) {
  .favItem:nth-child(3n) {
    border-right: none;
  }
}

@media (max-width: 480px) {
  .favItem:nth-child(n + 10) {
    border-bottom: none;
  }
}

@media (max-width: 480px) {
  .favItem:nth-child(n + 13) {
    display: none;
  }
}

.favItem:hover {
  background-color: #f3f3f3;
}

.staging_box {
  width: 20rem;
  height: 10rem;
  padding: 1rem;
  position: absolute;
  margin: auto;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
}

.staging_box__input {
  width: 100%;
  font-size: 1.5rem;
  padding: 0.8rem 0.5rem;
  margin-bottom: 1rem;
  border: #ccc thin solid;
  border-radius: 0.3rem;
  text-align: center;
}

.staging_box__btn {
  width: 100%;
  background-color: #f9f9f9;
  border: #ccc thin solid;
  padding: 0.8rem 0.2rem;
  border-radius: 0.3rem;
  cursor: pointer;
  text-transform: uppercase;
}

.staging_box__btn:hover {
  background-color: #eee;
}
